<template>
	<div>
		<LoadingScreen v-if="loading_screen" />
		<contentHeader title="Stok Opname" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
          						<h3 class="card-title col-6">Stok Opname</h3>
          					</div>
          					<p class="text-center mt-2" v-if="cek_data">Stok Obat Masih Kosong</p>
			          		<div class="card-body">
			          			<div class="row align-items-center" v-if="!cek_data">
			          				<div class="col-lg-2">
			          					<span>Jumlah Baris</span>
			          					<select class="custom-select form-control" v-model="page" @change="changePage">
			            					<option value="10">10</option>
			            					<option value="15">15</option>
			            					<option value="20">20</option>
			            					<option value="25">25</option>
			            					<option value="30">30</option>
			            					<option value="40">40</option>
			            					<option value="45">45</option>
			            					<option value="50">50</option>
			            				</select>
			          				</div>
			          				<div class="col-lg-6"></div>
		            				<div class="col-lg-4">
		            					<span>Cari Data</span>
		            					<input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
		            				</div>
			          			</div>
			          			<form @submit.prevent="prosesKartuStok" v-if="!cek_data" class="mt-4">
			          				<div class="row px-lg-2">
			          					<div class="col-8">
			          						<div class="form-group">
					                    <label>Nama Obat</label>
					                  </div>
			          					</div>
			          					<div class="col-2">
					                    <label>Stok Sistem</label>
			          					</div>
			          					<div class="col-2">
					                    <label>Stok Real</label>
			          					</div>
			          				</div>

			          				<div class="row px-lg-2" v-for="row in obat" :key="row.id">
			          					<div class="col-8">
			          						<div class="form-group">
					                    <input type="text" class="form-control" v-model="row.nama" disabled="">
					                  </div>
			          					</div>
			          					<div class="col-2">
					                    <input type="number" class="form-control" disabled="" v-model="row.stok_akhir">
			          					</div>
			          					<div class="col-2">
					                    <input type="number" class="form-control" v-model="row.stok_real">
			          					</div>
			          				</div>
			          			<div class="row ml-2 mt-2">	
				          			<button type="submit" class="btn btn-primary d-flex align-items-center mt-4">
										<div>Update Stok</div>	
										<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
										  <span class="sr-only">Loading...</span>
										</div>
									</button>
			          			</div>
			          			</form>
			          		</div>
			          		<div class="card-footer">
								<nav aria-label="...">
								  <ul class="pagination">
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
								      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
								    </li>
								    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
								      <a class="page-link" href="#">Next</a>
								    </li>
								  </ul>
								</nav>
		          			</div>
			          	</div>
			          </div>
			      </div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader'
	import LoadingScreen from '@/components/LoadingScreen'

	import { computed, ref, onMounted } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import Swal from 'sweetalert2'

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const loading = ref(false)

	const { links, paginateData, pagination, getDataResult, page, cek_data, changePage, loading_screen, obat, length, kata_kunci } = useShowData(user, cabang.value)

	const prosesKartuStok = () => {
		loading.value = true
		
		let data = {
			'user' : user.value,
			'stok' : obat.value
		}

		axios.post(`api/obat/stokOpname/${length.value}/${cabang.value.tipe}`, data)
		.then((response) => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil Menanbahkan Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			console.log(response)
			obat.value = []
			getDataResult()
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal Menanbahkan Data',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500
			})	
			loading.value = false
			console.log(error)
		})
	}

	onMounted(() => {
		getDataResult()
	})

	function useShowData (user, cabang) {
		const data_result = ref([])
		const memuat_data = ref(true)
		const cek_data = ref(false)
		const page = ref(10)
		const links = ref([])
		const pagination = ref([])
		const loading_screen = ref(false)
		const obat = ref([])
		const length = ref('')
		const kata_kunci = ref('')

		const getDataResult = async () => {
			data_result.value = []
	    	memuat_data.value = true
			obat.value = []
	    	cek_data.value = false
	    	loading_screen.value = true
	    	length.value = ''

	    	let parameter = ''
	    	if (kata_kunci.value != '') {
	    		parameter = kata_kunci.value
	    	} else {
	    		parameter = 'emptyParameter'
	    	}

			let { data } = await axios.get(`api/stok_produk/getStokOpname/${user.value.cabang_id}/${page.value}/${parameter}/${cabang.tipe}`)
			
			if (data == 'kosong') {
				cek_data.value = true
				memuat_data.value = false
				data_result.value = []
				loading_screen.value = false
				obat.value = []
			} else {
				obat.value = []
				memuat_data.value = false
				cek_data.value = false
				data_result.value = data.data
				links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
	    		loading_screen.value = false
	    		for (var i = 0; i < data.data.length; i++) {
					obat.value.push({
						'id' : data_result.value[i].id,
						'nama' : data_result.value[i].produk.nama,
						'stok_akhir' : data_result.value[i].stok_akhir,
						'stok_real' : ''
					})
				}
				length.value = data.data.length
			}

		}

		const changePage = () => {
			getDataResult()
		}

		const paginateData = async (url) => {

  		if (url != null) {
  			data_result.value = []
    		memuat_data.value = true
	    	loading_screen.value = true
    		obat.value = []

    		let link_url = new URL(url)
    		link_url = `${link_url.pathname}${link_url.search}`
    		
    		let { data } = await axios.get(`${link_url}`)

    		
    		if (data != 'kosong') {
    			memuat_data.value = false
    			data_result.value = data.data	
    			links.value = data.links
    			links.value.splice(0, 1)
    			links.value.splice(links.value.length-1, 1)
    			pagination.value = data
	    		loading_screen.value = false
	    		for (var i = 0; i < data.data.length; i++) {
					obat.value.push({
						'id' : data_result.value[i].id,
						'nama' : data_result.value[i].produk.nama,
						'stok_akhir' : data_result.value[i].stok_akhir,
						'stok_real' : ''
					})
				}
				length.value = data.data.length
    		}
  		}
  	}

  	return { memuat_data, links, paginateData, pagination, getDataResult, page, cek_data, changePage, loading_screen, obat, length, kata_kunci }
	}
</script>